/** @jsxImportSource @emotion/react */
import { css,keyframes } from "@emotion/react";


const spin = keyframes`
  0% {
    transform:rotate(0deg); 
  }
  100% {
    transform:rotate(360deg); 
  }
`;

export const DateTimeRangePickerWrapper = css`

  display: flex;
  align-items: center;
  margin: 5px;
  border-radius: 4px; 
  flex-flow: 0;
  justify-content: end;
  
  

  > div{
    border: 1px solid rgba(0,0,0,0.8);
    display: flex;
    gap: 10px;
    padding: 12px 12px 10px 12px;
    background: rgba(44,47,75,0.7);
    border-radius: 4px;
    .rotate{
      animation:${spin} 1s linear infinite;
    }
  }

  .picker{
      position: relative;
      label{
        color: white;
        font-size: 1.1rem;
        cursor: pointer;
        background-color: rgba(24,27,45,0.7);
        border-radius: 4px;
        padding: 4px 5px;
        &:hover{
          text-decoration: underline;
        }
      }
    input{
      bottom: -4px;
      left: 0;
    }
  }

`