/** @jsxImportSource @emotion/react */
import { InfoSectionStyle } from '../BarStyle.css';
import { BetaChangeStyle, ProductionBoxStyles } from './InfobarContent.css';
import InfoIcon from '../Icons/InfoIcon';
import { Tooltip } from 'react-tooltip';
// import { format  } from "date-fns"
import { Line } from 'react-chartjs-2';
import { truncateString } from './InfobarContent';

const currencyFormater = Intl.NumberFormat("en-US", {maximumFractionDigits: 0});

const datasetColors = [
    {
        borderColor: 'rgba(86, 118, 207, 0.57)', 
        backgroundColor: 'rgba(86, 118, 207, 0.17)',
    },  {
        borderColor: 'rgba(90, 207, 86, 0.57)',
        backgroundColor: 'rgba(90, 207, 86, 0.17)',
    },{
        borderColor: 'rgba(199, 207, 86, 0.57)',
        backgroundColor: 'rgba(199, 207, 86, 0.17)',
    }, {
        borderColor: 'rgba(86, 207, 191, 0.57)',
        backgroundColor: 'rgba(86, 207, 191, 0.17)',
    }, {
        borderColor: 'rgba(207, 86, 86, 0.57)',
        backgroundColor: 'rgba(207, 86, 86, 0.17)',
    }
]

const createDataSets = (data) => {    
    var datasets = data.rewards.betaDaily.map((x, i) => {
        const ds = {
            label: truncateString(x.sn),
            data: x.rewards.map(y => ({x: y.ts, y: y.amount})),
            borderColor: datasetColors[i].borderColor,
            backgroundColor: datasetColors[i].backgroundColor,
            fill: true
        }
        return ds
    });

    const chartData = {
        datasets: datasets,
    };

    return chartData;

}

const getChartOptions = (data) => {

    const minMaxAmounts = data.rewards.betaDaily.flatMap(obj => {
      return obj.rewards.map(reward => reward.amount)
    });
    const min = Math.min(...minMaxAmounts) * 0.8;
    const max = null //Math.max(...minMaxAmounts) * 3;

    const cleanChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false // Removes the legend
          },
          tooltip: {
            enabled: true,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            titleColor: 'white',
            bodyColor: 'white',
            cornerRadius: 4,
            padding: 10,
            displayColors: false // Removes the colored boxes in tooltips
          }
        },
        scales: {
          x: {
            type: 'time',
            display: false, // Removes x-axis
            grid: {
              display: false
            },
            // max: new Date(),
            time: {
                tooltipFormat: "yyyy-MM-dd",
            },
          },
          y: {
            display: false, // Removes y-axis
            stacked: true,
            min: min,
            max: max,
            grid: {
              display: false
            }
          }
        },
        elements: {
          point: {
            radius: 0, // Removes points from line charts
            hoverRadius: 6 // Shows points on hover
          },
          line: {
            tension: 0.4, // Smooth curves
            borderWidth: 2
          },
          bar: {
            borderWidth: 0 // Clean bars without borders
          }
        },
        layout: {
          padding: {
            top: 0,
            right: 0,
            bottom: 30,
            left: 0
          }
        },
        interaction: {
          intersect: false,
          mode: 'index'
        }
      };

      return cleanChartOptions;
}

const calculateTotal = (data, days) => {
   const now = new Date();
   now.setHours(0, 0, 0, 0);
  
   const cutoffDate = new Date(now);
   cutoffDate.setDate(cutoffDate.getDate() - days);
   
   // Calculate total rewards across all SNs
   return data.reduce((total, item) => {
     // Get rewards within the time window for this SN
     const snRewards = item.rewards.reduce((snTotal, reward) => {
       const rewardTime = new Date(reward.ts);
       if (rewardTime >= cutoffDate) {
         return snTotal + reward.amount;
       }
       return snTotal;
     }, 0);
     
     return total + snRewards;
   }, 0);
}

const BetaRewards = ({gatewayRequest, background}) => {
    return (
        <div css={[InfoSectionStyle, background]}>
            <h3>Rewards</h3>
            <div css={ProductionBoxStyles}>
                <div>
                    <Tooltip id="beta" place={"top-start"} noArrow 
                        content={<span>While srcful is in beta we hand out rewards <br/> to gateways that participate. 
                            <br/>You can read more in the Docs about the rewards.</span>} 
                    />
                    <h4 data-tooltip-id="beta">Rewards <InfoIcon /></h4>
                    
                    {gatewayRequest.data && <>
                        <p>
                            <span>
                                {currencyFormater.format(gatewayRequest.data.rewards?.betaInfo.total)}
                                
                                <span css={BetaChangeStyle}>
                                  <span>+{currencyFormater.format(calculateTotal(gatewayRequest.data.rewards.betaDaily,1))} <span>last 24 hours</span></span>
                                  <span>+{currencyFormater.format(calculateTotal(gatewayRequest.data.rewards.betaDaily,7))} <span>last 7 days</span></span>
                                </span>
                            </span>
                        </p>
                    </>}
                    
                </div>
                <div className='no-padding'>
                    <Tooltip id="beta-daily"  style={{ zIndex: 99 }} place={"top-start"} noArrow 
                                content={<span>
                                    Last 30 days of rewards.
                                </span>} 
                    />
                    <h4 data-tooltip-id="beta-daily">Daily <InfoIcon /></h4>
                    <div style={{height: '87px'}}> {
                        gatewayRequest.data && <Line data={createDataSets(gatewayRequest.data)} options={getChartOptions(gatewayRequest.data)} /> 
                    }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BetaRewards;