import React from 'react';
import { Line } from 'react-chartjs-2';
import ErrorBox from '../Infobar/ErrorBox';

export const options = () => { return {
    responsive: true,
    animation: false,
    cubicInterpolationMode: "monotone",
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                boxWidth: 10,  // Adjust the width of the colored box
                boxHeight: 10,
                font:{
                    size: 11,
                },
                generateLabels: function(chart) {
                    const datasets = chart.data.datasets;
                    if(datasets.length < 2) return [];
                    return datasets.map(dataset => ({
                        color: 'white',
                        text: dataset.label.toUpperCase(),
                        fillStyle: chart.isDatasetVisible(datasets.indexOf(dataset)) ? 
                            dataset.borderColor : 
                            "transparent",
                        strokeStyle: dataset.borderColor,
                        lineWidth: 2,
                        fontColor: 'white',
                        hidden: !chart.isDatasetVisible(datasets.indexOf(dataset)),
                        datasetIndex: datasets.indexOf(dataset)
                    }));
                }
            }
        },
        title: {
            display: true,
            text: 'Power Consumption 24h (kW)',
            color:"#f9f1f1"
        }
    },
    scales: {
        x: {
            type: 'time',
            // min: startTime,
            max: new Date(),
            time: {
                tooltipFormat: "yyyy-MM-dd HH:mm",
                displayFormats: { hour: 'HH:mm' },
            },
            beginAtZero: true,
            ticks:{
                color: "#FAF9F6",
            },
            grid: {
                display: false
            },
            border: {
                color: "transparent"
            }
        },
        y: {
            ticks:{
                color: "#FAF9F6",
                count: 5,
                format: { maximumFractionDigits: 2, minimumFractionDigits: 0 }
            },
            min: 0,
            border: {
                color: "black"
            }
        }
    }
  }};

const EnergyMeterGraph = ({data}) => {
    if(!data) return <ErrorBox message={"Unable to retrieve graph data"} />;
    const labels = data.map(x => x.ts);
    const dataset = data.map(x => {
        return x.power
    });
    const l1dataset = data.map(x => {
        return x.l1
    });
    const l2dataset = data.map(x => {
        return x.l2
    });
    const l3dataset = data.map(x => {
        return x.l3
    });
    return (
        <Line 
            options={options()}
            data={{
                labels: labels,
                datasets: [{      
                    label: "Total",
                    data: dataset,
                    spanGaps: false,
                    fill:true,
                    pointBorderColor: 'transparent',
                    pointRadius: 4,
                    borderWidth: 2,
                    pointStyle: false,
                    borderColor: 'rgba(255, 140, 0, 0.8)',
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                        gradient.addColorStop(0, "rgba(255, 140, 0, 0.8)");
                        gradient.addColorStop(1, "rgba(255, 140, 0, 0)");
                        return gradient;
                    }, 
                    pointBackgroundColor: 'rgb(255, 106, 0)',
                },{
                    label: "L1",
                    data: l1dataset,
                    spanGaps: false,
                    fill:true,
                    pointBorderColor: 'transparent',
                    pointRadius: 4,
                    pointStyle: false,
                    borderColor: 'rgba(150,75,0, 0.8)',
                    borderWidth: 1,
                    pointBackgroundColor: 'rgba(150,75,0, 0.8)',
                },{
                    label: "L2",
                    data: l2dataset,
                    spanGaps: false,
                    fill:true,
                    pointBorderColor: 'transparent',
                    pointRadius: 4,
                    pointStyle: false,
                    borderWidth: 1,
                    borderColor: 'rgba(0,0,0, 0.8)',
                    pointBackgroundColor: 'rgba(20,20,20, 1)',
                },{
                    label: "L3",
                    data: l3dataset,
                    spanGaps: false,
                    fill:true,
                    pointBorderColor: 'transparent',
                    pointRadius: 4,
                    borderWidth: 1,
                    pointStyle: false,
                    borderColor: 'rgba(128,128,128, 1)',
                    pointBackgroundColor: 'rgba(100,100,100, 1)',
                }]
            }}
        
        />

    );
};

export default EnergyMeterGraph;