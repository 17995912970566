/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

export const AppStyle = css`

    display: flex;
    flex-direction: column;
    height: 100svh;

`

export const mainAreaContentStyle = css`
    flex: 1;
    position: relative;
    display: flex;

`