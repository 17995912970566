/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { DateTimeRangePickerWrapper } from './DateTimeRangePicker.css';
import { UilArrow, UilSpinnerAlt } from '@iconscout/react-unicons';

// Custom hook for debounced callback
const useDebounceCallback = (callback, delay) => {
  const timeoutRef = useRef(null);
  const activePickerRef = useRef(null);
  const [isDebouncing, setIsDebouncing] = useState(false);
  
  const cancelCallback = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    activePickerRef.current = null;
    setIsDebouncing(false);
  }, []);

  const debouncedCallback = useCallback((data, pickerId) => {
    if (activePickerRef.current && activePickerRef.current !== pickerId) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
    
    activePickerRef.current = pickerId;
    setIsDebouncing(true);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(data);
      activePickerRef.current = null;
      setIsDebouncing(false);
    }, delay);
  }, [callback, delay]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return { debouncedCallback, cancelCallback, isDebouncing };
};

const DateTimeRangePicker = ({ 
  startDate,
  endDate,
  callback,
  debounceDelay = 1500
}) => {
  const startInputRef = useRef(null);
  const endInputRef = useRef(null);
  const [error, setError] = useState('');

  // Format date object to YYYY-MM-DDThh:mm format for datetime-local input
  function formatDateTimeLocal(date) {
    const d = new Date(date);
    d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
    return d.toISOString().slice(0, 16);
  }

  // Parse datetime-local string to Date object
  function parseDateTimeLocal(dateString) {
    return new Date(dateString);
  }

  const formattedStartDate = formatDateTimeLocal(startDate);
  const formattedEndDate = formatDateTimeLocal(endDate);

  // Create debounced callback
  const { debouncedCallback, cancelCallback, isDebouncing } = useDebounceCallback((dateRange) => {
    console.log('Debounced callback:', dateRange);
    callback?.(dateRange);
  }, debounceDelay);

  // Validate date range and trigger callbacks
  useEffect(() => {
    if (startDate >= endDate) {
      setError('Start date must be before end date');
      return;
    }
    setError('');
    callback({ startDate, endDate });
  }, [startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleStartDateChange = (e) => {
    const newStartDate = parseDateTimeLocal(e.target.value);
    debouncedCallback({ startDate: newStartDate, endDate });
  };

  const handleEndDateChange = (e) => {
    const newEndDate = parseDateTimeLocal(e.target.value);
    debouncedCallback({ startDate, endDate: newEndDate });
  };
  
  return (
    <div css={DateTimeRangePickerWrapper}>
      <div>
        <div className="picker">
          <label 
            onClick={() => {
              cancelCallback();
              startInputRef.current?.showPicker();
            }} 
            style={{ cursor: 'pointer' }}
          >
            {formattedStartDate}
          </label>
          <input
            ref={startInputRef}
            type="datetime-local"
            value={formattedStartDate}
            max={formattedEndDate}
            onChange={handleStartDateChange}
            style={{ position: 'absolute', opacity: 0, width: 0, height: 0 }}
          />
        </div>
        {isDebouncing ? <UilSpinnerAlt className="rotate" size="20" color="white" /> : <UilArrow size="20" color="white"/>}
        <div className="picker">
          <label 
            onClick={() => {
              cancelCallback();
              endInputRef.current?.showPicker();
            }} 
            style={{ cursor: 'pointer' }}
          >
            {formattedEndDate}
          </label>
          <input
            ref={endInputRef}
            type="datetime-local"
            value={formattedEndDate}
            min={formattedStartDate}
            max={formatDateTimeLocal(new Date())}
            onChange={handleEndDateChange}
            style={{ position: 'absolute', opacity: 0, width: 0, height: 0 }}
          />
        </div>
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
};

export default DateTimeRangePicker;