/** @jsxImportSource @emotion/react */
import React from 'react';
import { SOON } from './Energymeter.css';

const Energymeter = () => {
    return (
        <div css={SOON}>
            🔌👀
        </div>
    );
};

export default Energymeter;