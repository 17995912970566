/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { ExplorerContext } from '../../ExplorerContext';
import { useQuery } from '@tanstack/react-query';
import { Line } from 'react-chartjs-2';
import { toTimeZoneDate } from '../../DateHelpers';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
  } from 'chart.js';
import useDebounce from '../../Hooks/useDebounce';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    zoomPlugin
  );

const options = (timeZone, onZoomComplete, startDate, endDate) => {
    return {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        plugins: {
            zoom: {
                pan: {
                    enabled: true,
                    modifierKey: 'ctrl',
                    mode: 'x',
                    onPanComplete: onZoomComplete,
                },
                zoom: {
                    onZoomComplete : onZoomComplete,
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    mode: 'x',
                    drag: {
                        enabled: true,
                        backgroundColor: 'rgba(0,0,0,0.1)',
                    },
                }
            }
        },
        scales: {
            x: {
                type: 'time',
                min: toTimeZoneDate(new Date(startDate),timeZone),
                max: toTimeZoneDate(new Date(endDate),timeZone),
                time: {
                    tooltipFormat: "yyyy-MM-dd HH:mm",
                    // displayFormats: { 
                    //     hour: shouldUse24HourFormat(timeZone) ? 'HH:mm' : 'h:mm a',
                    //     minute: shouldUse24HourFormat(timeZone) ? 'HH:mm' : 'h:mm a',
                    //     day: 'MMM d',
                    // },
                    unit: 'day',
                },
                beginAtZero: true,
                ticks:{
                    color: "#FAF9F6",
                },
                grid: {
                    display: false
                },
                border: {
                    color: "transparent"
                }
            },
            y: {
                ticks:{
                    color: "#FAF9F6",
                    count: 5,
                    format: { maximumFractionDigits: 2, minimumFractionDigits: 0 }
                },
                min: 0,
                border: {
                    color: "black"
                }
                
            }
        }
    }
}

const getResolution = (startDate, endDate, typeOf) => {
    // Calculate the time difference in hours
    const diffInHours = (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60);
    // Set resolution based on time difference
    if (diffInHours <= 12) {
        return typeOf === "Hardware" ? "2m" : "5m";  
    } else if (diffInHours <= 24) {
        return "5m";  // 1 minute for <= 24 hours
    } else if (diffInHours <= 48) {
        return "15m"; // 10 minutes for <= 48 hours
    } else {
        return "30m";  // 1 hour for everything else
    }
}

const Solar = ({gateway, startDate, endDate, timeZone, onZoom, isLoading}) => {


    const { loadExtendedSolarData } = useContext(ExplorerContext)
    const zoomDebounce = useDebounce((chart) => {
        const {min, max} = chart.chart.scales.x;
        onZoom(new Date(min), new Date(max))
    }, 1500);
    
    var { data } = useQuery({
        queryKey: ["solar-" + gateway.id, startDate, endDate],
        enabled: gateway.id != null,
        keepPreviousData: true,
        queryFn: async () => {
            console.log("Loading solar data", startDate, endDate)
            var sns = gateway.ders.filter(d => d.type === "Solar").map(d => d.sn)
            var response = await loadExtendedSolarData(sns, startDate, endDate, getResolution(startDate,endDate, gateway.typeOf))
            isLoading(false)
            return response;
        }
    })


    var datasets = Object.keys(data?.derData??[]).map((key,i) => {
        return {
            label: "DER-"+(i+1),
            data: data.derData[key].histogram.map(d => { return {x: d.ts, y: d.power} }),
            fill: true,
            borderColor: 'rgb(0, 255, 132)',
            backgroundColor: 'rgba(0, 255, 132, 0.5)',
            tension: 0.5,
            pointRadius: 3,     
            pointBackgroundColor:"#017E7A"       
        }
    })

    return (    
        <Line
            options={options(timeZone, (c) =>{
                isLoading(true)
                zoomDebounce(c)
            }
            ,startDate, endDate)}
            data={{datasets:datasets}} 
        />  
    );
};

export default Solar;