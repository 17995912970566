/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";


export const ExtendedWrapperStyle = css`

    width: 100%;
    background-color: rgba(0,0,0,0.8);
    position: relative;
    z-index: 1;
    background: linear-gradient(rgba(29, 29, 29,0.9) 0%, rgba(0, 0, 0,0.4) 100%);
    padding: 0 20px 20px 20px;

    .inner{
        display: flex;
        flex-direction: column;
        height: 100%;
        
    }

`

export const ExtendedHeadingStyle = css`

    color: white;
    display: flex;
    align-items: center;

    h1{
        font-size: 1.4rem;
        font-family: "Inter";
        border-bottom: 1px solid rgba(0,0,0,0.3);
        margin-left: 3px;
        padding: 10px 25px 10px 0;
        flex-shrink: 0;
        a{
            position: relative;
            top: 4px;
            margin-right: 5px
        }
    }

    ul{
        display: flex;
        border-top: 1px solid rgba(0,0,0,0.5);
        border-left: 1px solid rgba(0,0,0,0.5);
        align-self: end;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        li{
            border-right: 1px solid rgba(0,0,0,0.5);
            flex-shrink: 0;
            background: rgba(25, 33, 61, 0.4);
            border-bottom: 1px solid rgba(0,0,0,0.5);
            position: relative;
            &:first-child {
                border-top-left-radius: 4px;
            }
            &:last-child {
                border-top-right-radius: 4px;
            }
            a{
                padding: 10px 20px;
                display: block;
                font-size: 1.1rem;
                color: rgba(255,255,255,0.5);
                text-decoration: none;
            }
            span{
                position: absolute;
                font-size: 7px;
                transform: rotate(30deg);
                display: block;
                padding: 2px 5px 3px 5px;
                border-radius: 4px;
                border: 1px solid rgba(0, 255, 132,0.3);
                background-color: rgba(0,0,0,0.5);
                top: -0px;
                right: -20px;
                z-index: 1;
            }
            &.active{
                background: rgba(25, 33, 51, 1);
                border-bottom: none;
                a{
                    color: white;
                    text-decoration: underline;
                }
            }
        }
    }
    &:after{
        content: '';
        width: 100%;
        margin-right: 3px;
        align-self: flex-end;
        height: 1px;
        border-bottom: 1px solid rgba(0,0,0,0.5);
        
    }

`

export const ExtendedContentStyle = css`
    border-left: 1px solid rgba(0,0,0,0.5);
    border-right: 1px solid rgba(0,0,0,0.5);
    border-bottom: 1px solid rgba(0,0,0,0.5);
    flex:1;
    width: calc(100% - 2px);
    box-shadow: rgba(0, 0, 0, 0.9) 0px 10px 15px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
    .data{
        flex: 1;
        overflow: hidden;
        position: relative;
        padding: 10px;
        
    }
`

export const ExtendedBackground = css`

    background: linear-gradient(rgba(25, 33, 51, 0.9) 0%,  rgba(25, 33, 51, 0.8) 100%);
    

`