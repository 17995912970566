import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import GatewaySelector from './Infobar/GatewaySelector';
import { InfobarContent } from './Infobar/InfobarContent';
import Extended from './Extended/Extended';


const infoBarRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [{
      path: "/hex/:hex",
      element: <><GatewaySelector /><InfobarContent /></>,
    },{
      path: "/:hex",
      element: <><GatewaySelector /><InfobarContent /></>,
    },{
      path: "/egw/:gwId",
      element: <><GatewaySelector /><InfobarContent /></>,
    },{
      path: "/:hex/:gwId",
      element: <><GatewaySelector /><InfobarContent /></>,
    },{
      path: "/:hex/:gwId/details/:derType",
      element: <Extended />,
    }
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={infoBarRouter}  />
    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
