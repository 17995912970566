


export const cleanDerSn = (derSn) => {
    return derSn.replace(/^.{2}-/, '');
}

const minutesDiff = (dateTimeValue2, dateTimeValue1) => {
    var differenceValue =(dateTimeValue2.getTime() - dateTimeValue1.getTime()) / 1000;
    differenceValue /= 60;
    return Math.abs(Math.round(differenceValue));
}

export const isOnline = (latest, minutes) => {
    if (!latest) return false;
    return minutesDiff(new Date(latest.ts), new Date()) < minutes;
}


