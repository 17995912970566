import { useCallback, useRef } from 'react';

function useDebounce(callback, delay) {
  // Store timeout reference
  const timeoutRef = useRef(null);

  // Return memoized debounced callback
  return useCallback((...args) => {
    // Clear existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set new timeout
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);
}

export default useDebounce;