/** @jsxImportSource @emotion/react */
import { useRef, useState } from 'react';
import { Cog } from '../Footer/Footer';
import { css } from '@emotion/react';
import ToggleSwitch from '../Form/ToogleSwitch';
import { useOnClickOutside } from '../Hooks/useOnClickOutside';
import { medium } from '../Global.css';

const settingButtonWrapper = css`

    /* position: absolute;
    top: 10px;
    height: 1px;
    right: 10px;
    width: calc(100% - 10px); */

    button{
        right: 0;
        background: none;
        border: none;
        fill: rgba(249,249,249,0.7);
        padding: 0;
        &:hover{
            fill: rgba(249,249,249,1);
        }
    }


`

const settingMenu = css`

    position: absolute;
    top: calc(100% + 20px);
    right: 5px;
    background: rgba(24, 24, 24, 0.95);
    padding: 8px;
    border-radius: 3px;
    box-shadow:  0 0 5px rgb(0, 0, 0, 0.9);
    width: 80%;
    user-select: none;
    ${medium}{
        width: 50%;
    }
    

`

const Settings = ({showValidationDataCallback, showValidationData}) => {

    const [open, setOpen] = useState(false);
    const settingRef = useRef();
    useOnClickOutside(settingRef, () => setOpen(false));

    return (
        <>
            <span ref={settingRef} css={settingButtonWrapper}>
                <button onClick={() => setOpen(!open)}><Cog/></button>
            
            {open && <div css={settingMenu}>
                <ToggleSwitch text={"Show validation data"} isOn={showValidationData} handleToggle={() => {
                    showValidationDataCallback(!showValidationData)
                }
                } />
            </div>}
            </span>
        </>
    );
};

export default Settings;