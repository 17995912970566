/** @jsxImportSource @emotion/react */
import { useContext } from "react"
import { InfoSectionStyle, OfflineStyle, OnlineStyle, UpdatedStyle } from "../../BarStyle.css"
import EnergyMeterIcon from '../../Icons/EnergyMeterIcon';
import { ContentFooterLinks, EnergyMeterStyle, ProductionBoxStyles } from '../InfobarContent.css';
import { useQuery } from "@tanstack/react-query"
import { ExplorerContext } from "../../ExplorerContext"
import EnergyMeterGraph from "../../ProductionGraph/EnergyMeterGraph";
import { Tooltip } from "react-tooltip";
import InfoIcon from "../../Icons/InfoIcon";
import Expandable from "../Expandable";
import { truncateString } from "../InfobarContent";
import CopyToClipboard from "../CopyToClipboard";
import { cleanDerSn, isOnline } from "./DerHelper";
import EnergyDailyGraph from "../../ProductionGraph/EnergyDailyGraph";

const EnergyMeter = ({ gatewayRequest, ders, background }) => {
    
    const { loadEnergyMeterData } = useContext(ExplorerContext)

    const {data, isSuccess, isRefetching} = useQuery({
        queryKey: ["energymeterdata", gatewayRequest.data?.id],
        enabled: gatewayRequest.isSuccess,
        queryFn: async () => {
          return loadEnergyMeterData(ders[0].sn) //FIX?
        },
        refetchInterval: 30000
    })

    return <div css={[InfoSectionStyle, background]}>
        <h3><EnergyMeterIcon style={EnergyMeterStyle} />Energymeter {!data ? <></> : isOnline(data?.derData?.energyMeter?.latest,30) ? <span css={OnlineStyle}>Online</span> : <span css={OfflineStyle}>Offline</span>}</h3>
        <span css={UpdatedStyle}>Updated: {data?.derData.energyMeter?.latest ? new Date(data.derData.energyMeter.latest.ts).toLocaleString("sv") : "-"}</span>
        <div css={ProductionBoxStyles}>
            <div>
                <Tooltip id="currentPower" place={"top-start"} noArrow content="Current power consumption" />
                <h4 data-tooltip-id="currentPower">Consumption <InfoIcon /></h4>
                <p className={isRefetching ? "refetching" : null}>{
                    `${data?.derData?.energyMeter?.latest?.consumption ?? "-"} kW`
                }
                </p>
            </div>
            <div>
                <Tooltip id="today" place={"top-start"} noArrow content="Current power delivery to grid" />
                <h4 data-tooltip-id="today">Delivery<InfoIcon /></h4>
                <p>{`${data?.derData?.energyMeter?.latest?.delivery?? "-"} kW`}</p>
            </div>
        </div>


        {isSuccess && <EnergyMeterGraph
            data={data.derData?.energyMeter.powerConsumption}
        />}
        <hr />
        {isSuccess && <EnergyDailyGraph data={data.derData?.energyMeter.dailyPowerConsumption}  />}

        {ders && ders.some(c => c) 
            &&
                <Expandable>
                    {ders.map((der, idx) => (
                    <><h3 title={der.lastSeen}>DER{ders.length > 1 ? `-${idx+1}` : null} metadata</h3>
                    <ul css={[ContentFooterLinks]}>
                        {/* <li>
                            Make: <span>{der.meta?.make ?? "Unknown"}</span>
                        </li>
                        <li>
                            Rated Power: <span>{der.meta?.nominalPower ? 
                                der.meta?.nominalPower/1000+ "kW" : 
                                "Unknown"
                            }</span>
                        </li>
                        <li>
                            Datapoints (10m): <span>{der.meta?.dataPoints ?? "-"}</span>
                        </li> */}
                        <li>
                            Sn: <span className={`derid${idx+1}`}>{truncateString(cleanDerSn(der.sn))}</span><CopyToClipboard text={der.sn} copiedClass={`deridCopied${idx+1}`} />
                        </li>
                    </ul>
                    </>
                ))}
                
                </Expandable>
        }
    </div>
    
};

export default EnergyMeter;