/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';

const loadingAnimation = keyframes`
  0% {
    transform: translateX(-100%) scaleX(1);
  }
  45% {
    transform: translateX(0%) scaleX(0.4);
  }
  50% {
    transform: translateX(0%) scaleX(0.4);
  }
  55% {
    transform: translateX(0%) scaleX(0.4);
  }
  100% {
    transform: translateX(150%) scaleX(0.1);
  }
`;

const fadeOutAnimation = keyframes`
  0% {
    opacity: 1;
    
  }
  100% {
    opacity: 0;
    
  }
`;

const containerStyles = css`
    width: 100%;
    height: 3px;
    border-radius: 0.5rem;
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
`;

const progressBarContainerStyles = css`
    width: 100%;
    height: 3px;
    background-color: rgba(0,0,0,0.4);
    overflow: hidden;
`;

const progressBarStyles = css`
    height: 100%;
    background-color:rgba(59, 131, 246,0.4);
    background: linear-gradient(
        90deg,
        rgba(34, 197, 94, 0) 0%,
        rgba(59, 131, 246, 1) 50%,
        rgba(34, 197, 94, 0) 100%
      );
    width: 50%;
    display: none;
    
    body.loading & {
        display: block;
        transform-origin: center;
        animation: ${loadingAnimation} 2s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
    body:not(.loading) & {
      display: block;
      background: linear-gradient(
        90deg,
        rgba(34, 197, 94, 0) 0%,
        rgba(34, 197, 94, 1) 50%,
        rgba(34, 197, 94, 0) 100%
      );
      width: 100%;
      animation: ${fadeOutAnimation} 0.7s ease-out forwards;
    }
`;

const LoadingBar = () => {
  return (
    <div css={containerStyles}>
      <div css={progressBarContainerStyles}>
        <div css={progressBarStyles} />
      </div>
    </div>
  );
};

export default LoadingBar;