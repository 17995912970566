/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { medium, large } from '../Global.css'


export const FooterStyle = (open) => css`
    z-index:1;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.4);
    border-top-width: 0px;
    border-top-style: solid;
    display: flex;
    align-items: center;
    padding: 0 5px;
    ${medium}{
        padding: 0 15px;
        gap: 15px;
    }
    gap: 10px;
    font-size: 14px;
    ${open ? `
        ${medium}{
            border-top-width: 1px;
            border-image: linear-gradient(90deg, transparent 0%, transparent 50%,rgba(0,0,0, 0.1) 50%) 1;
        }
        ${large}{
            border-image: linear-gradient(90deg, transparent 0%, transparent 476px, rgba(0,0,0, 0.1) 476px) 1;
        }` : 
    "border-top-color: rgba(254, 215, 0, 0.3);"
    }
    button.footeritem{
        cursor:pointer;
    }
    .footeritem{
        display:flex;
        align-items: center;
        text-decoration: none;
        background:none;
        border: none;
        color: #D3D3D3;
        font-family: "Inter";
        gap: 3px;
        white-space: nowrap;

        &.right{
            margin-left: auto;
        }
        

        &.hideInMobile{
            display:none;
            ${medium}{
                display:flex;
            }
        }

        &:hover{
            color: #fff;
            
                &.kWh{
                    fill:rgba(189, 176, 0, 1);
                }
                &.devicesonline{
                    fill: rgba(0, 191, 99, 1);
                }
                &.blocks{
                    .stack1{ fill: #c80f77; }
                    .stack2{ fill: #6a86ba; }
                    .stack3{ fill: #a98e23; }
                }
                &.api{
                    fill: #1c9ccb;
                }
            
        }
        span{
            cursor:default;
        }
        svg{
            
            fill: #D3D3D3;
        }
    }
`