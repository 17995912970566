/** @jsxImportSource @emotion/react */
import React from 'react';
import { SOON } from './Battery.css';

const Battery = () => {
    return (
        <div css={SOON}>
            🔋👀
        </div>
    );
};

export default Battery;