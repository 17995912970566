/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const SOON = css`

    display: flex;
    justify-content: center;
    font-size: 300px;
    align-items: center;
    height: 100%;

`
