import React from 'react';
import { Bar } from 'react-chartjs-2';
const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const weekColors = {
    even: { bg: 'rgba(75, 192, 192, 0.6)', border: 'rgba(75, 192, 192, 1)' },
    odd: { bg: 'rgba(89, 122, 168, 0.6)', border: 'rgba(89, 122, 168, 1)' }      
};


const EnergyDailyGraph = ({ data }) => {

    const getWeekNumber = (date) => {
        //Uses Thursday as a reference point (ISO standard) to determine the week number
        // Create a new date object to avoid modifying the input
        const tempDate = new Date(date);
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        tempDate.setHours(0, 0, 0, 0);
        tempDate.setDate(tempDate.getDate() + 4 - (tempDate.getDay() || 7));
        // Get first day of year
        const yearStart = new Date(tempDate.getFullYear(), 0, 1);
        // Calculate full weeks to nearest Thursday
        const weekNumber = Math.ceil((((tempDate - yearStart) / 86400000) + 1) / 7);
        return weekNumber;
    };

    const chartData = {
        labels: data.map(entry => entry.ts),
        datasets: [
            {
                label: 'Daily Energy Consumption',
                data: data.map(entry => entry.power),
                borderWidth: 1,
                backgroundColor: data.map(entry => {
                    const date = new Date(entry.ts);
                    const weekNum = getWeekNumber(date);
                    return weekNum % 2 === 0 ? weekColors.even.bg : weekColors.odd.bg;
                }),
                borderColor: data.map(entry => {
                    const date = new Date(entry.ts);
                    const weekNum = getWeekNumber(date);
                    return weekNum % 2 === 0 ? weekColors.even.border : weekColors.odd.border;
                }),
            },
        ],
    };

    const options = {
        responsive: true,
        animation: false,
        cubicInterpolationMode: "monotone",
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: 'Daily Power Consumption (kWh)',
                color:"#f9f1f1"
            },
            tooltip:{
                callbacks:{
                    title: function(context) {
                        const chart = context[0].chart;
                        const date = new Date(data[context[0].dataIndex].ts);
                        const dayName = weekdays[date.getDay()];
                        const formattedDate = chart.scales.x.options.time.tooltipFormat;
                        return `${dayName}, ${chart.scales.x._adapter.format(date, formattedDate)}`;
                    },
                    label: function(context) {
                        const value = context.raw;
                        return `${value} kWh`;
                    }
                }
            },
        },
        scales: {
            x: {
                type: 'time',
                min: new Date(new Date() - (30 * 86400000)),
                max: new Date(),
                time: {
                    tooltipFormat: "yyyy-MM-dd",
                    displayFormats: { hour: 'HH:mm' },
                },
                beginAtZero: true,
                ticks:{
                    color: "#FAF9F6",
                },
                grid: {
                    display: false
                },
                border: {
                    color: "transparent"
                },
            },
            y: {
                ticks:{
                    color: "#FAF9F6",
                    count: 5,
                    format: { maximumFractionDigits: 2, minimumFractionDigits: 0 }
                },
                min: 0,
                border: {
                    color: "black"
                }
            }
    }
      

    };

    return <Bar data={chartData} options={options} />;
};

export default EnergyDailyGraph;