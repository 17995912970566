/** @jsxImportSource @emotion/react */
import { useContext } from "react"
import { FooterStyle } from "./Footer.css"
import { ExplorerContext } from "../ExplorerContext"
import { UilCircuit } from '@iconscout/react-unicons'
import { Tooltip } from "react-tooltip"
import { useQuery } from "@tanstack/react-query"



export const Footer = () => {

    const { infoBarOpen, loadStats } = useContext(ExplorerContext)

    const {data} =  useQuery({
        queryKey: ["stats"],
        queryFn: async () => {
            return await loadStats()
        },
        refetchInterval: 60000
    })

    return <footer css={FooterStyle(infoBarOpen)}>

        <Tooltip id="kWh" place={"top-start"} noArrow content="Total kWh produced in the network last hour" />
        <span className="footeritem" data-tooltip-id="kWh">
            <Bolt className="kWh" /><span>{(data?.data?.stats?.currentKW ?? '-')} kWh</span>
        </span>

        <Tooltip id="mw" place={"top-start"} noArrow content="Total peak power of the network last hour" />
        <span className="footeritem" data-tooltip-id="mw">
            <Bolt className="kWh" /><span>{(data?.data?.stats?.totalPeakPower ?? '-')} MW</span>
        </span>

        <Tooltip id="devicesonline" place={"top-start"} noArrow content="Total DERS online" />
        <span className="footeritem" data-tooltip-id="devicesonline">
            <UilCircuit className="devicesonline" width="16px" height="16px"></UilCircuit><span>{(data?.data?.stats?.dersOnline ?? '-')}</span>
            </span>

        <span className="footeritem right"></span>

        <a className="footeritem hideInMobile" href="https://api.srcful.dev/playground">
            <Code className="api" /> API
        </a>

        <a className="footeritem hideInMobile" rel="noreferrer" target="_blank" href="https://app.srcful.io">
            <Cog className="blocks" /> Gateway Configurator
        </a>


    </footer>
}




const Bolt = (props) => {
    return <svg style={{transform: "rotate(10deg)"}} height="16px" width="16px" viewBox="0 0 512 512"  {...props} >
        <g>
            <polygon points="426.667,213.333 284.444,213.333 284.444,0 85.333,298.667 227.556,298.667 227.556,512"/>
        </g>
    </svg>
}

export const Code = (props) => {
    return <svg stroke="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="16px" width="16px" {...props}  >
        <path d="M278.9 511.5l-61-17.7c-6.4-1.8-10-8.5-8.2-14.9L346.2 8.7c1.8-6.4 8.5-10 14.9-8.2l61 17.7c6.4 1.8 10 8.5 8.2 14.9L293.8 503.3c-1.9 6.4-8.5 10.1-14.9 8.2zm-114-112.2l43.5-46.4c4.6-4.9 4.3-12.7-.8-17.2L117 256l90.6-79.7c5.1-4.5 5.5-12.3.8-17.2l-43.5-46.4c-4.5-4.8-12.1-5.1-17-.5L3.8 247.2c-5.1 4.7-5.1 12.8 0 17.5l144.1 135.1c4.9 4.6 12.5 4.4 17-.5zm327.2.6l144.1-135.1c5.1-4.7 5.1-12.8 0-17.5L492.1 112.1c-4.8-4.5-12.4-4.3-17 .5L431.6 159c-4.6 4.9-4.3 12.7.8 17.2L523 256l-90.6 79.7c-5.1 4.5-5.5 12.3-.8 17.2l43.5 46.4c4.5 4.9 12.1 5.1 17 .6z">
        </path>
    </svg>
}

// const Blocks = (props) => {
//     return <svg width="16" height="16" viewBox="0 0 24 24" {...props}>
//         <path className="stack1" d="M11.6444 1.58965C11.8664 1.47012 12.1336 1.47012 12.3556 1.58965L22.1056 6.83965C22.3485 6.97046 22.5 7.22409 22.5 7.5C22.5 7.77591 22.3485 8.02954 22.1056 8.16035L12.3556 13.4104C12.1336 13.5299 11.8664 13.5299 11.6444 13.4104L1.89443 8.16035C1.65149 8.02954 1.5 7.77591 1.5 7.5C1.5 7.22409 1.65149 6.97046 1.89443 6.83965L11.6444 1.58965Z" />
//         <path className="stack2" d="M3.26468 10.6018L10.9333 14.731C11.5992 15.0896 12.4008 15.0896 13.0667 14.7311L20.7353 10.6018L22.1056 11.3396C22.3485 11.4704 22.5 11.7241 22.5 12C22.5 12.2759 22.3485 12.5295 22.1056 12.6603L12.3556 17.9103C12.1336 18.0299 11.8664 18.0299 11.6444 17.9103L1.89443 12.6603C1.65149 12.5295 1.5 12.2759 1.5 12C1.5 11.7241 1.65149 11.4704 1.89443 11.3396L3.26468 10.6018Z" />
//         <path className="stack3" d="M10.9333 19.231L3.26468 15.1018L1.89443 15.8396C1.65149 15.9704 1.5 16.2241 1.5 16.5C1.5 16.7759 1.65149 17.0295 1.89443 17.1603L11.6444 22.4103C11.8664 22.5299 12.1336 22.5299 12.3556 22.4103L22.1056 17.1603C22.3485 17.0295 22.5 16.7759 22.5 16.5C22.5 16.2241 22.3485 15.9704 22.1056 15.8396L20.7353 15.1018L13.0667 19.2311C12.4008 19.5896 11.5992 19.5896 10.9333 19.231Z" />
//     </svg>
// }


export const Cog = (props) => {

    return <svg width="16" height="16" viewBox="0 -256 1792 1792" {...props}>
        <g transform="matrix(1,0,0,-1,121.49153,1285.4237)">
            <path d="m 1024,640 q 0,106 -75,181 -75,75 -181,75 -106,0 -181,-75 -75,-75 -75,-181 0,-106 75,-181 75,-75 181,-75 106,0 181,75 75,75 75,181 z m 512,109 V 527 q 0,-12 -8,-23 -8,-11 -20,-13 l -185,-28 q -19,-54 -39,-91 35,-50 107,-138 10,-12 10,-25 0,-13 -9,-23 -27,-37 -99,-108 -72,-71 -94,-71 -12,0 -26,9 l -138,108 q -44,-23 -91,-38 -16,-136 -29,-186 -7,-28 -36,-28 H 657 q -14,0 -24.5,8.5 Q 622,-111 621,-98 L 593,86 q -49,16 -90,37 L 362,16 Q 352,7 337,7 323,7 312,18 186,132 147,186 q -7,10 -7,23 0,12 8,23 15,21 51,66.5 36,45.5 54,70.5 -27,50 -41,99 L 29,495 Q 16,497 8,507.5 0,518 0,531 v 222 q 0,12 8,23 8,11 19,13 l 186,28 q 14,46 39,92 -40,57 -107,138 -10,12 -10,24 0,10 9,23 26,36 98.5,107.5 72.5,71.5 94.5,71.5 13,0 26,-10 l 138,-107 q 44,23 91,38 16,136 29,186 7,28 36,28 h 222 q 14,0 24.5,-8.5 Q 914,1391 915,1378 l 28,-184 q 49,-16 90,-37 l 142,107 q 9,9 24,9 13,0 25,-10 129,-119 165,-170 7,-8 7,-22 0,-12 -8,-23 -15,-21 -51,-66.5 -36,-45.5 -54,-70.5 26,-50 41,-98 l 183,-28 q 13,-2 21,-12.5 8,-10.5 8,-23.5 z" />
        </g>
    </svg>

}